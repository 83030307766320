@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.loading {
  margin: 15px auto 0;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  border: 0.3rem solid rgba(0, 0, 0, 0.2);
  border-top-color: rgb(0, 0, 0);
  -webkit-animation: spin 1s infinite linear;
          animation: spin 1s infinite linear;
}
.loading--double {
  border-style: double;
  border-width: .5rem;
}

.loading-pulse {
  position: relative;
  width: 6px;
  height: 24px;
  background: rgba(255, 255, 255, 0.2);
  -webkit-animation: pulse 750ms infinite;
          animation: pulse 750ms infinite;
  -webkit-animation-delay: 250ms;
          animation-delay: 250ms;
}
.loading-pulse:before, .loading-pulse:after {
  content: '';
  position: absolute;
  display: block;
  height: 16px;
  width: 6px;
  background: rgba(255, 255, 255, 0.2);
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  -webkit-animation: pulse 750ms infinite;
          animation: pulse 750ms infinite;
}
.loading-pulse:before {
  left: -12px;
}
.loading-pulse:after {
  left: 12px;
  -webkit-animation-delay: 500ms;
          animation-delay: 500ms;
}
