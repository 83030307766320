.banner {
  text-align: center;
}

.banner img {
  height: 85px;
}

.error {
  margin: 12px 0;
  padding: 16px;
  color: red;
  background-color: #fdd;
}

.navbar {
  background-color: #FEF7DD;
  border: solid #91A4B8;
}

.navbar .navbar-brand {
  color: #25395B;
}

.navbar .nav-item .nav-link {
  color: #25395Baa;
}
